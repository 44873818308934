import React from "react";
import theme from "theme";
import { Theme, Em, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Барбершоп UrbanTrim
			</title>
			<meta name={"description"} content={"Створіть свій стиль, вдосконаліть свій вигляд"} />
			<meta property={"og:title"} content={"Барбершоп UrbanTrim"} />
			<meta property={"og:description"} content={"Створіть свій стиль, вдосконаліть свій вигляд"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/2-1.jpg?v=2024-05-31T10:12:17.583Z) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						<Em>
							де досконалий догляд за волоссям поєднується з неперевершеним комфортом.
						</Em>
					</Text>
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Пориньте у світ преміум-догляду в UrbanTrim Barbershop
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Наша перукарня розроблена, щоб стати вашим особистим місцем відпочинку від суєти повсякденного життя.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					З прихильністю до якості та деталізації наші професійні перукарі гарантують, що кожна стрижка та гоління будуть виконані до досконалості. Незалежно від того, чи готуєтеся ви до особливої ​​події чи просто хочете оновити свій образ, UrbanTrim — це місце, де ви отримаєте чудові послуги догляду.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Про UrbanTrim
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					UrbanTrim Barbershop — це не лише стрижки, а й створення досвіду, який покращить ваш особистий стиль і благополуччя. Наш просторий магазин може похвалитися стильною, затишною атмосферою, де традиційні перукарські техніки поєднуються з сучасною естетикою.{" "}
					<br />
					<br />
					Ми пишаємося своєю прискіпливою увагою до деталей і здатністю адаптувати кожну послугу до індивідуальних потреб наших клієнтів. Від розкішного гоління до точної стрижки, наші пропозиції створені, щоб покращити ваш догляд і забезпечити вам найкращий вигляд і відчуття.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10:12:17.577Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0511d712a0023345bcc/images/1-3.jpg?v=2024-05-31T10%3A12%3A17.577Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Чому варто обрати UrbanTrim?
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Досвідчені перукарі
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Наша команда складається з досвідчених професіоналів, які спеціалізуються як на класичних, так і на сучасних стилях.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Розслаблююча атмосфера
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Насолоджуйтеся комфортною обстановкою, яка відчуває себе як другий дім.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Продукція найвищої якості
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Ми використовуємо лише найкращі засоби для догляду, щоб забезпечити дбайливе ставлення до вашого волосся та шкіри.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6659a0511d712a0023345bcc/images/1-2.jpg?v=2024-05-31T10:12:17.568Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Будемо на зв'язку
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Щоб записатися на зустріч чи отримати запит, зв’яжіться з нами!
				</Text>
				<Link
					href="/faq"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					До наших послуг
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});